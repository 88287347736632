<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
              <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                <b-row>
                  <b-col xs="12" sm="12" md="6" lg="6">
                    <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="org_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{$t('user_role.organization')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                            plain
                            v-model="region.org_id"
                            :options="orgList"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                        >
                            <template v-slot:first>
                            <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                            </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                        </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="6" lg="6">
                    <ValidationProvider name="Service Name" vid="service_id" rules="required|min_value:1">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="service_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{$t('org_pro.service_namel')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                            v-model="region.service_id"
                            :options="serviceNamesList"
                            id="service_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                        >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                            </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                        </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="6" lg="6">
                    <ValidationProvider name="Region" vid="region_office_id" rules="required|min_value:1">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="region_office_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{$t('warehouse_config.region')}} {{$t('globalTrans.office')}}<span class="text-danger">*</span>
                        </template>
                        <b-form-select
                            plain
                            v-model="region.region_office_id"
                            :options="regionNameList"
                            id="region_office_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                        >
                            <template v-slot:first>
                            <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                            </template>
                        </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                <b-row v-for="(setDistrict, index) in setDistricts" :key="index">
                  <b-col lg="6" sm="6">
                      <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          :label="$t('warehouse_config.district')"
                          label-for="district"
                          slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{$t('warehouse_config.district')}} <span class="text-danger">*</span>
                        </template>
                            <b-form-select
                                plain
                                v-model="region.district_id[setDistrict.step_id]"
                                :options="districtList"
                                id="district_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                          <div class="invalid-feedback d-block" v-if="customError.hasOwnProperty('district_id.' + index)">
                            The District has already been taken.
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                  </b-col>
                  <b-col lg="2" sm="2">
                    <b-button v-show="index == setDistricts.length-1" variant=" iq-bg-success" size="sm" @click="add(setDistrict.step_id)"><i class="ri-add-line m-0"></i></b-button>
                    <b-button  v-show="index == setDistricts.length-1" variant=" iq-bg-danger" size="sm" @click="remove(index, setDistrict.step_id)"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </b-col>
                </b-row>
                <div class="row">
                  <div class="col-sm-3"></div>
                  <div class="col text-right">
                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                    &nbsp;
                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                  </div>
                </div>
              </b-form>
            </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl } from '../../../../../config/api_config'
import { regionStore, regionUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getRegionData()
      const distId = []
            tmp.master_region_districts.forEach(function (item, index) {
                distId.push(item.district_id)
            })
       tmp.district_id = distId
    // Object.freeze(tmp)
      this.region = tmp

      const districtLength = distId.length
      if (districtLength) {
        this.setDistricts.splice(0, 1)
          for (let i = 0; i < districtLength; i++) {
            const stepName = {
                step_id: i,
                district_id: distId[i]
            }
            this.setDistricts.push(stepName)
        }
      }
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      region: {
        district_id: [],
        org_id: 0,
        service_id: 0,
        region_office_id: 0
      },
      setDistricts: [
        {
          step_id: 0,
          district_id: []
        }
      ],
      serviceNamesList: [],
      regionNameList: [],
      customError: []
    }
  },
  computed: {
    orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    districtList: function () {
      return this.$store.state.commonObj.districtList.filter(item => item.status === 0)
    }
  },
  watch: {
      'region.org_id': function (newVal, oldVal) {
          if (newVal !== oldVal) {
              this.serviceNamesList = this.getSevicename(newVal)
              this.regionNameList = this.getRegionNameList(newVal)
          }
      }
  },
  methods: {
    getSevicename (orgId) {
        const service = this.$store.state.licenseRegistration.commonObj.serviceNamesList.filter(item => item.status === 1 && item.org_id === orgId)
        return service
    },
    getRegionNameList (orgId) {
      const tmpList = this.$store.state.commonObj.officeList.filter(item => item.status === 0 && item.org_id === orgId && item.is_regional_office === 1)
      return tmpList.map(item => ({ text: item.text, value: item.value }))
    },
    getRegionData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      if (this.region.id) {
        result = await RestApi.putData(licenseRegistrationServiceBaseUrl, `${regionUpdate}/${this.id}`, this.region)
      } else {
        result = await RestApi.postData(licenseRegistrationServiceBaseUrl, regionStore, this.region)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('mutateWarehouseProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.customError = result.errors
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    },
    add (stepId) {
      const stepName = {
        step_id: stepId + 1,
        district_id: ''
      }
      this.setDistricts.push(stepName)
    },
    remove (key, stepId) {
        if (this.id) {
            const index = this.region.district_id.indexOf(this.setDistricts[key].district_id)
            if (index > -1) {
             this.region.district_id.splice(index, 1)
            }
        } else {
            this.region.district_id.splice(stepId, 1)
        }
        this.setDistricts.splice(key, 1)
       this.region.district_id.splice(stepId, 1)
    }
  }
}
</script>
