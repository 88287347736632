<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:body>
                <b-row>
                    <b-col lg="6" sm="6">
                    <ValidationProvider name="Organization" vid="org_id" rules="required">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="org_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{$t('user_role.organization')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                            plain
                            v-model="search.org_id"
                            :options="orgList"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                        >
                            <template v-slot:first>
                            <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                            </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                        </b-form-group>
                    </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="6">
                    <ValidationProvider name="Service Name" vid="service_id" rules="required">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="service_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{$t('org_pro.service_namel')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                            v-model="search.service_id"
                            :options="serviceNamesList"
                            id="service_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                        >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                            </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                        </b-form-group>
                    </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6">
                        <ValidationProvider name="Region" vid="region_office_id">
                            <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="region_office_id"
                            >
                            <template v-slot:label>
                                {{$t('warehouse_config.region')}}
                            </template>
                            <b-form-select
                                plain
                                v-model="search.region_office_id"
                                :options="regionNameList"
                                id="region_office_id"
                            >
                                <template v-slot:first>
                                <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                                </template>
                            </b-form-select>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6">
                    <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('warehouse_config.region')}}</h4>
                    </template>
                    <template v-slot:headerAction>
                        <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
                             <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                         <template v-slot:cell(org_id)="data">
                                            <span class="capitalize">{{ getOrgName(data.item.org_id) }}</span>
                                        </template>
                                         <template v-slot:cell(service_id)="data">
                                            <span class="capitalize">{{ getServiceName(data.item.service_id) }}</span>
                                        </template>
                                         <template v-slot:cell(region_office_id)="data">
                                            <span class="capitalize">{{ getOfficeName(data.item.region_office_id) }}</span>
                                        </template>
                                        <template v-slot:cell(master_region_districts)="data">

                                        <span class="capitalize" v-for="(item, index) in data.item.master_region_districts" :key="index"><span>{{ $i18n.locale === 'bn' ? districtListBn[item.district_id] : districtList[item.district_id] }}<br></span></span>
                                        </template>
                                        <template v-slot:cell(status)="data">
                                        <!-- {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }} -->
                                        <b-badge variant="danger" v-if="data.item.status == 1">{{$t('globalTrans.inactive')}}</b-badge>
                                        <b-badge variant="success" v-else>{{$t('globalTrans.active')}}</b-badge>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                        <!-- <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button> -->
                                        <!-- <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button> -->
                                        <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 size="sm" @click="edit(data.item)">
                                            <i class="ri-ball-pen-fill"></i>
                                        </a>
                                        <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" size="sm" @click="remove(data.item)" v-if="data.item.status === 1">
                                            <i class="fas fa-toggle-off"></i>
                                        </a>
                                        <a href="javascript:" class="btn_table_action table_action_status" title="Status" size="sm" @click="remove(data.item)" v-else>
                                            <i class="fa fa-toggle-on"></i>
                                        </a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-if="pagination.totalRows"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>

        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>

    </b-container>
</template>
<script>
import Form from './Form'

import RestApi, { licenseRegistrationServiceBaseUrl } from '../../../../../config/api_config'
import { regionList, regionToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form
    },
    data () {
        return {
            search: {
                org_id: 0,
                service_id: 0,
                region_office_id: 0
            },
            testId: 0,
            rows: [],
            districtList: [],
            districtListBn: [],
            serviceNamesList: [],
            regionNameList: []
        }
    },
    watch: {
        'search.org_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.serviceNamesList = this.getSevicename(newVal)
                this.regionNameList = this.getRegionNameList(newVal)
            }
        }
    },
    computed: {
        orgList: function () {
            return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
        },
        formTitle () {
            return (this.testId === 0) ? this.$t('warehouse_config.region') : this.$t('warehouse_config.region')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('org_pro.organization'), class: 'text-center' },
                { label: this.$t('service_name.service_name'), class: 'text-center' },
                { label: this.$t('warehouse_config.region') + ' ' + this.$t('globalTrans.office'), class: 'text-center' },
                { label: this.$t('warehouse_config.district'), class: 'text-center' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'org_id' },
                { key: 'service_id' },
                { key: 'region_office_id' },
                { key: 'master_region_districts' },
                { key: 'status' },
                { key: 'action' }
                ]
                 keys[4] = {key: 'master_region_districts'}
            } else {
                keys = [
                { key: 'index' },
                { key: 'org_id' },
                { key: 'service_id' },
                { key: 'region_office_id' },
                { key: 'master_region_districts' },
                { key: 'status' },
                { key: 'action' }
                ]
                keys[4] = {key: 'master_region_districts'}
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
        this.loadData()
    },
    mounted () {
        this.getDistrictList()
    },
    methods: {
        async searchData () {
            this.loadData()
        },
        remove (item) {
        this.changeStatus(warehouseServiceBaseUrl, regionToggleStatus, item, 'warehosue', 'regionList')
        },
        loadData () {
            var regionIDSearch = ''
            if (this.search.region_office_id) {
                regionIDSearch = this.search.region_office_id
            }
            const params = Object.assign({}, this.search, { region_office_id: regionIDSearch, page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(licenseRegistrationServiceBaseUrl, regionList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', response.data.data)
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getSevicename (orgId) {
            const service = this.$store.state.licenseRegistration.commonObj.serviceNamesList.filter(item => item.status === 1 && item.org_id === orgId)
            return service
        },
        getRegionNameList (orgId) {
          const tmpList = this.$store.state.commonObj.officeList.filter(item => item.status === 0 && item.org_id === orgId && item.is_regional_office === 1)
          return tmpList.map(item => ({ text: item.text, value: item.value }))
        },
        getDistrictList () {
            const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
            const custom_district = []
            const custom_district_bn = []
            districtList.forEach(function (item, index) {
                custom_district[item.value] = item.text_en
                custom_district_bn[item.value] = item.text_bn
            })
            this.districtList = custom_district
            this.districtListBn =custom_district_bn
        },
        getOrgName (orgId) {
            const tmpOrg = this.orgList.find(org => org.value === orgId)
            return this.$i18n.locale === 'en' ? tmpOrg !== undefined ? tmpOrg.text_en : '' : tmpOrg !== undefined ? tmpOrg.text_bn : ''
        },
        getServiceName (serviceId) {
            const ServiceName = this.$store.state.licenseRegistration.commonObj.serviceNamesList.find(item => item.value === serviceId)
            return this.$i18n.locale === 'en' ? ServiceName !== undefined ? ServiceName.text_en : '' : ServiceName !== undefined ? ServiceName.text_bn : ''
        },
        getOfficeName (officeId) {
            const ServiceName = this.$store.state.commonObj.officeList.find(item => item.status === 0 && item.value === officeId)
            return this.$i18n.locale === 'en' ? ServiceName !== undefined ? ServiceName.text_en : '' : ServiceName !== undefined ? ServiceName.text_bn : ''
        }
    }
}
</script>
